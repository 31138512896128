import registry from '../../../core/repository/registry'
import SuperRepository from '../../../core/repository/superRepository'
import Options from './Options'

export default {
  components: { Options },
  props: {
    invalid: {
      type: Boolean,
    },
    items: {
      type: [Array, Function],
      default: () => (repo, params) => repo.list(params),
    },
    optionName: {
      type: Function,
      default: (opt) => opt.name,
    },
    optionValue: {
      type: Function,
      default: (opt) => opt.uuid,
    },
    searchQuery: {
      type: Function,
      default: (s) => {
        return {
          search: s,
        }
      },
    },
    optionFilter: {
      type: Function,
    },
    placeholder: {
      type: String,
      default: '',
    },
    repository: {
      type: String,
      default: null,
    },
    nullable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      search: '',
      repo: null,
      options: this.items instanceof Array ? this.items : [],
      pager: {
        loading: false,
        stopped: this.disabled || this.items instanceof Array, /* If no need to fetch options then the pager is stopped */
        nextPage: 1,
        limit: this.pageSize,
      },
    }
  },
  computed: {
    opts() {
      return this.callOptionFilter()
    },
    optsEmpty() {
      return !this.options.length && this.pager.stopped
    },
  },
  watch: {
    search() {
      this.reset()
      this.load()
    },
    choice: {
      handler() {
        this.dispatch()
      },
      deep: true,
    },
  },
  methods: {
    /* Reset pagination state */
    reset() {
      this.pager.nextPage = 1
      this.options = this.items instanceof Array ? this.items : []
      this.pager.stopped = this.disabled || this.items instanceof Array
    },
    /* Load next page of options */
    load() {
      if (this.pager.stopped) {
        return
      }
      if (this.pager.loading) {
        return
      }
      this.pager.loading = true
      this.items(this.findRepository(), this.defaults())
        .then(({ data }) => {
          this.options = this.options.concat(data.payload.items.map((o) => this.selected(o)))
          this.pager.nextPage++
          this.pager.stopped = data.payload.pagination.current >= data.payload.pagination.last
          this.onLoadDone(this.pager.nextPage)
        })
        .finally(() => this.pager.loading = false)
    },
    /* Hook to catch from components */
    onLoadDone() {
    },
    /* Mock for components */
    selected() {
    },
    /* Mock for components */
    dispatch() {
    },
    /* Merge pagination with additional request params */
    defaults() {
      const pagination = {
        pagination: {
          page: this.pager.nextPage,
          limit: this.pager.limit,
        },
      }
      return {
        ...pagination,
        ...this.callSearchQuery(),
      }
    },
    /* Execute searchQuery */
    callSearchQuery() {
      if (!this.search) {
        return {}
      }
      return this.searchQuery.call(this, this.search)
    },
    /* Execute optionName */
    callOptionName(item) {
      if (!item) {
        return null
      }
      return this.optionName.call(this, item)
    },
    /* Execute optionValue */
    callOptionValue(item) {
      if (!item) {
        return null
      }
      return this.optionValue.call(this, item)
    },
    /* Execute optionFilter if only given items is static array of options */
    callOptionFilter() {
      if (this.items instanceof Array) {
        return this.options.filter(this.optionFilter || ((o) => this.callOptionName(o)
          .toLowerCase()
          .includes(this.search.toLowerCase())))
      }
      return this.options
    },
    /* Try to find repository inside the registry */
    findRepository() {
      if (!(this.repo instanceof SuperRepository)) {
        this.repo = registry.get(this.repository)
        if (!(this.repo instanceof SuperRepository)) {
          throw new Error(`Repository <${this.repository}> does not exists.`)
        }
      }
      return this.repo
    },
  },
}
