import SuperRepository from '../superRepository'

export default new class OrderLineRepository extends SuperRepository {
  baseUri() {
    return 'report/dashboard/stats'
  }

  getDailyPlan = async (params = {}) => this.get('daily-plan-impl', params);

  getUserStats = async (params = {}) => this.get('employee-order', params);
  getBranchStats = async (params = {}) => this.get('branch-payment-stats', params);
}()
