import SuperRepository from '../superRepository'

export default new class ReportsRepository extends SuperRepository {
  baseUri() {
    return 'report'
  }

  serviceCategorySumsByOrders(criteria) {
    return this.get('service-category-sums-by-orders', criteria)
  }

  numServicesByCategory(criteria) {
    return this.get('num-services-by-category', criteria)
  }

  numServicesByBranch(criteria) {
    return this.get('num-services-by-branch', criteria)
  }

  sumsByOrders(criteria) {
    return this.get('sums-by-orders', criteria)
  }
}()
