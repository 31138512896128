<!--suppress JSCheckFunctionSignatures -->
<template>
<div v-infinite-scroll="loader" :infinite-scroll-distance="distance" class="scroll-div scrollbar-primary">
  <div class="force-overflow">
    <b-dropdown-item
      v-for="option in options"
      :key="valueResolver(option)"
      :active="Array.isArray(selection)?selection.includes(option):option === selection"
      @click.native.prevent.capture="onSelect($event, option)"
    >
      <span class="ml-4">
        <slot name="option" :option="option">
          {{ keyResolver(option) }}
        </slot>
      </span>
    </b-dropdown-item>
    <b-dropdown-item v-if="loading" class="text-center my-2">
      <span class="spinner spinner-sm" />
    </b-dropdown-item>
    <template v-if="empty">
      <template v-if="!!$slots.isEmpty">
        <slot name="isEmpty" />
      </template>
      <div v-else class="text-center text-muted py-3">
        <span class="text-muted">{{ $t('BASE.NO_DATA') }}</span>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'Options',
  directives: { infiniteScroll },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    loader: {
      type: Function,
    },
    selection: null,
    keyResolver: {
      type: Function,
    },
    valueResolver: {
      type: Function,
    },
    distance: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
    },
    empty: {
      type: Boolean,
    },
    keepOpen: {
      type: Boolean,
    },
  },
  methods: {
    onSelect(evt, option) {
      if (this.keepOpen) {
        evt.stopPropagation()
      }
      this.$emit('select', option)
    },
  },
}
</script>

<style scoped>

</style>
