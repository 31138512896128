// eslint-disable-next-line import/no-unresolved
import * as AdminRepos from './admin'
// eslint-disable-next-line import/no-unresolved
import * as CommonRepos from './common'
// eslint-disable-next-line import/no-unresolved
import * as CompanyRepos from './company'

const registry = new Map()

Object.values(CompanyRepos)
  .forEach((r) => registry.set(r.baseUri(), r))
Object.values(CommonRepos)
  .forEach((r) => registry.set(r.baseUri(), r))
Object.values(AdminRepos)
  .forEach((r) => registry.set(r.baseUri(), r))

export default registry

/** @requires 'babel-plugin-wildcard' */
