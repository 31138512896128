export default class Label {
  /** @type String */
  color
  /** @type String */
  icon

  constructor(color, icon) {
    this.color = color
    this.icon = icon
  }

  persistentProps() {
    return {
      color: this.color,
      icon: this.icon,
    }
  }
}
