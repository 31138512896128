import Vue from 'vue'
import Entity from './entity'

export default class Reminder extends Entity {
  /** @type String */
  uuid
  /** @type String */
  event
  /** @type String */
  text
  /** @type Label */
  label
  /** @type User */
  recipient
  /** @type Moment */
  date
  /** @type Moment */
  viewedAt

  persistentProps() {
    return {
      event: this.event,
      text: this.text,
      label: this.label.persistentProps(),
      recipient: this.recipient ? this.recipient.id() : null,
      date: this.date ? this.date.format('yyyy-MM-DD HH:mm') : null,
      viewedAt: this.viewedAt ? this.viewedAt.format('yyyy-MM-DD HH:mm') : null,
    }
  }

  isViewed() {
    return this.viewedAt != null
  }

  toggleViewed() {
    if (this.viewedAt == null) {
      this.viewedAt = Vue.prototype.$moment()
    } else {
      this.viewedAt = null
    }
    return this
  }
}
