import Vue from 'vue'
import { typeOf } from '@/helpers/type'
import EntityRepository from '@/core/repository/entityRepository'
import BusinessUserRepository from '@/core/repository/company/businessUserRepository'
import Reminder from '@/model/reminder'
import Label from '@/model/label'

export default new class ReminderRepository extends EntityRepository {
  baseUri() {
    return 'company/reminders'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, Reminder)) {
      ent = new Reminder()
    }
    if (props.hasOwnProperty('uuid')) {
      ent.uuid = props.uuid
    }
    if (props.hasOwnProperty('text')) {
      ent.text = props.text
    }
    if (props.hasOwnProperty('event')) {
      ent.event = props.event
    }
    if (props.hasOwnProperty('label')) {
      ent.label = new Label(props.label.color || null, props.label.icon || null)
    }
    if (props.hasOwnProperty('recipient')) {
      ent.recipient = BusinessUserRepository.mapEntity(props.recipient || null)
    }
    if (props.hasOwnProperty('date')) {
      ent.date = Vue.prototype.$moment(props.date)
    }
    if (props.hasOwnProperty('viewedAt')) {
      ent.viewedAt = props.viewedAt === null ? null : Vue.prototype.$moment(props.viewedAt)
    }
    return ent
  }
}()
